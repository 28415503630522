/* Style the accordion section */

.accordion__section {
  padding: 20px;
  background-color: white;
  color: #047481;
}

/* Style the buttons that are used to open and close the accordion panel */

.accordion {
  background-color: white;
  color: #047481;
  cursor: pointer;
  padding: 18px;
  border: none;
  outline: none !important;
  /* display: flex;
  align-items: center;
  outline: none;
  transition: background-color 0.6s ease; */
}

.active-content {
  background-color: rgb(0, 127, 133, 0.1);
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.accordion:hover {
  background-color: rgb(0, 127, 133, 0.1);
}

/* Style the accordion content title */

.accordion__title {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

/* Style to rotate icon when state is active */

.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */

.accordion__content {
  background-color: white;
  color: black;
  text-decoration: underline;
  transition: max-height 0.6s ease;
}

.accordion__anchor {
  overflow-wrap: break-word;
  white-space: normal;
}

/* Style the accordion content text */

.accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}

.accordian-open {
  transform: rotate(90deg);
  transition: transform 0.6s ease;
}

.accordian-close {
  transition: transform 0.6s ease;
}
