@font-face {
  font-family: 'open-sans-semi';

  src: url(/static/media/OpenSans-Semibold-webfont.dc95b864.woff) format('woff');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'open-sans-regular';

  src: url(/static/media/OpenSans-Regular-webfont.c8ffdeb3.woff) format('woff');

  font-weight: normal;

  font-style: normal;
}
