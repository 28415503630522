@font-face {
  font-family: 'GoudyOldStyleMT';

  src: url(/static/media/GoudyOldStyleMT.b5238b03.ttf) format('ttf');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'GoudyOldStyleBold';

  src: url(/static/media/GoudyOldStyle-Bold.01045b5e.ttf) format('ttf');

  font-weight: normal;

  font-style: normal;
}
