:root {
  --tina-sidebar-width: 425px!important;
  --tina-font-family: 'open-sans-regular', sans-serif!important;
  --tina-color-primary-light: #007F85!important;
  --tina-color-primary: #007F85!important;
  --tina-color-primary-dark: #013639!important;
}

div[class^="FormBody-sc"] {
  background: white!important;
}

div[class^="FieldWrapper-sc"] input {
  border-radius: 0;
  padding: 20px 10px;
}

div[class^="FieldWrapper-sc"] input:hover {
  box-shadow: none;
}

button[class^="Button-sc"] {
  border-radius: 0px;
}

.edit-button div[class^="FieldsGroup-sc"]{
  padding-top: 0;
  padding-left: 0;
  margin-left: 0;
}

.edit-button div[class^="FieldWrapper-sc"] {
  padding: 0;
}

.edit-button div[class^="FieldWrapper-sc"] label[for="extendedHours"],
.edit-button div[class^="FieldWrapper-sc"] label[for="mainHours"]{
  display: none;
}

.edit-button div[class^="FieldWrapper-sc"] label[for="haveExtendedHours"]{
  width: 50%;
}

.edit-button div[class^="Header-sc"]{
  background: #047481;
  border-radius:0px;
  color: #fff;
  font-size: 0.9em;
  font-weight: 500;
  margin-left: 0;
  width: 55%;
}

.edit-button div[class^="ToggleElement-sc"]{
  padding: 3px;
}

.section-header {
  grid-column-start: 1;
  grid-column-end: 6;
}

.split-row-start {
  grid-column-start: 3;
  grid-column-end: 3;
  display: flex;
  justify-content: flex-end;
}

.add-split-hours {
  font-size: 12px;
  color: #007f85;
  grid-column-start: 3;
  grid-column-end: 6;
  display: flex;
  justify-content: flex-end;
}

/* .unsaved-changes {
  position: absolute;
  top: 50px;
  z-index: 1000;
  left: 20%;
} */