div[class*="wysiwyg-wrapper"] div div div button[data-tooltip="Heading"],
div[class*="wysiwyg-wrapper"] div div div button[data-tooltip="Strike"],
/* div[class*="wysiwyg-wrapper"] div div div button[data-tooltip="Link ⌘-K"],
div[class*="wysiwyg-wrapper"] div div div button[data-tooltip="Link ^-K"], */
div[class*="wysiwyg-wrapper"] div div div button[data-tooltip="Table"],
div[class*="wysiwyg-wrapper"] div div div button[data-tooltip="Blockquote"],
div[class*="wysiwyg-wrapper"] div div div button[data-tooltip="Codeblock ⌘-⌥-0"],
div[class*="wysiwyg-wrapper"] div div div button[data-tooltip="Codeblock ^-⌥-0"],
div[class*="wysiwyg-wrapper"] div div div button[data-tooltip="Unordered List ⌘-⌥-8"],
div[class*="wysiwyg-wrapper"] div div div button[data-tooltip="Unordered List ^-⌥-8"],
div[class*="wysiwyg-wrapper"] div div div button[data-tooltip="Ordered List ⌘-⌥-7"],
div[class*="wysiwyg-wrapper"] div div div button[data-tooltip="Ordered List ^-⌥-7"],
div[class*="wysiwyg-wrapper"] div div div button[data-tooltip="Undo ⌘-Z"],
div[class*="wysiwyg-wrapper"] div div div button[data-tooltip="Undo ^-Z"],
div[class*="wysiwyg-wrapper"] div div div button[data-tooltip="Redo ⌘-⇧-Z"],
div[class*="wysiwyg-wrapper"] div div div button[data-tooltip="Markdown mode"],
div[class*="wysiwyg-wrapper"] div div div button[disabled]

 {
  display: none;
}

div[class*="wysiwyg-wrapper"] div {
  margin-bottom: 0;
  border-bottom: none;
}

div[class*="wysiwyg-wrapper"] div div div {
  justify-content: flex-start;
  margin: 0;
  background: var(--ck-color-toolbar-background);
  border-color: #c4c4c4;
  border-radius: var(--ck-border-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;
  padding: 0 var(--ck-spacing-small);
}

div[class*="wysiwyg-wrapper"] div div div button {
  border: none;
  border-radius: var(--ck-border-radius);
  color: #333;
  flex: none;
  margin-top: var(--ck-spacing-medium);
  margin-bottom: var(--ck-spacing-small);
  margin-right: var(--ck-spacing-small);
  cursor: default;
  vertical-align: middle;
  padding: var(--ck-spacing-tiny);
  text-align: center;
  min-height: var(--ck-ui-component-min-height);
  line-height: 1;
  border: 1px solid transparent;
  transition: box-shadow .2s ease-in-out,border .2s ease-in-out;
}

div[class*="wysiwyg-wrapper"] div div div button:hover {
  background: var(--ck-color-button-default-hover-background);
  border-radius: var(--ck-border-radius);
}

div[class*="wysiwyg-wrapper"] div div div button svg {
  transform: scale(1.2)
}

div[class*="wysiwyg-wrapper"] div.ProseMirror,
textarea[name="mainHours.additionalHoursText"],
textarea[name="extendedHours.c_extendedAdditionalHoursText"] {
  border-radius: var(--ck-border-radius);
  border-color: #c4c4c4;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

div[class*="wysiwyg-wrapper"] div.ProseMirror:hover,
textarea[name="mainHours.additionalHoursText"]:hover,
textarea[name="extendedHours.c_extendedAdditionalHoursText"]:hover {
  box-shadow: none;
}

div[class*="wysiwyg-wrapper"] div.ProseMirror:focus,
textarea[name="mainHours.additionalHoursText"]:focus,
textarea[name="extendedHours.c_extendedAdditionalHoursText"]:focus {
  outline: none;
  border: var(--ck-focus-ring);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: var(--ck-inner-shadow),0 0;
}
