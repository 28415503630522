#websitePreviewFrame {
  width:100%;
  height: 70vh;
  position: relative;
}

/* in case you want to use the glare that Pete mentioned */

/* .glareEffectOnScreen {
  position: absolute;
    z-index: 10;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background: rgb(246,248,249);
    background: -moz-linear-gradient(-45deg, rgba(246,248,249,1) 0%, rgba(229,235,238,1) 50%, rgba(215,222,227,1) 51%, rgba(245,247,249,1) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(246,248,249,1) 0%,rgba(229,235,238,1) 50%,rgba(215,222,227,1) 51%,rgba(245,247,249,1) 100%);
    background: linear-gradient(135deg, rgb(246 248 249 / 12%) 0%,rgb(229 235 238 / 35%) 50%,rgb(215 222 227 / 22%) 51%,rgb(245 247 249 / 18%) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f8f9', endColorstr='#f5f7f9',GradientType=1 );
} */

.chromeheader {
  max-width: 91%;
  left: 8.25rem;
  position: relative;
  margin-right: 8.5rem;
}

@media (min-width: 840px) {
  .chromeheader {
  }
}

#fake-header-banner {
  bottom: 2.9rem;
}

.dummy-browser {
  padding: 10px;
  background: #dcdcdc;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  /* width: 95%; */
  position: relative;
  /* left: 3.5rem; */
  margin-top: 3rem;
}

@media (max-width: 400px) {
  .dummy-browser {
    transform: none !important;
  }
}

.left {
  width: 33%;
  left: 1rem;
  position: relative;
}

.right {
  width: 33%;
}

.middle {
  width: 33%;
}

/* Clear floats after the columns */

.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Windows browser icons */

.icon-square {
  margin-top: 4px;
  margin-right: 3px;
  height: 12px;
  width: 12px;
  background:#909095;
}

/* Style the input field */

.fakeBrowserURL {
  width: 100%;
  border-radius: 3px;
  border: none;
  background-color: white;
  margin-top: -8px;
  height: 25px;
  color: #666;
  padding: 5px;
}

.white-arrow {
  fill: #ffffff;
}

.white-arrow:hover {
  fill: #252f3f;
}