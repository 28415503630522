.user-modal {
  align-items: center!important;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center!important;
  margin: 1.75rem auto;
  position: relative;
  width: 80%;
  z-index: 100;
  top: 11rem;
}

.user-modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
  border: none;
}

.role-dropdown-contents:before,
.role-dropdown-contents:after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 2.5rem;
  border: 9px solid transparent;
  border-bottom-color: #fff;
}
