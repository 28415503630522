.dropdown {
  margin-top: 2rem;
  width: 16.25rem;
}

.dropdown-contents:before,
.dropdown-contents:after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 7.5rem;
  border: 11px solid transparent;
  border-bottom-color: #fff;
}

.support-dropdown-contents::before,
.support-dropdown-contents::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 4.5rem;
  border: 11px solid transparent;
  border-bottom-color: #fff;
}

.support-dropdown-contents {
  margin-top: 1rem;
}

.user-dropdown-contents {
  margin-top: 1rem;
  width: 116px;
}

.user-dropdown-contents::before,
.user-dropdown-contents::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 3rem;
  border: 9px solid transparent;
  border-bottom-color: #fff;
}

#publish-button:disabled {
  cursor: not-allowed;
  opacity: 50%;
}
