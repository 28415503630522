div[class^="PanelHeader"]::after {
  content: "Back";
}

div[class^="PanelHeader"] span {
  display: none !important;
}

div[class^="PanelHeader"] {
  height: 86px;
}

.rc-tabs-dropdown {
  position: absolute;
  background: #fefefe;
  max-height: 200px;
  overflow: auto;
}

.rc-tabs-dropdown-hidden {
  display: none;
}

.rc-tabs-dropdown-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.rc-tabs-dropdown-menu-item {
  padding: 4px 8px;
}

.rc-tabs-dropdown-menu-item-selected {
  background: #007F85;
}

.rc-tabs-dropdown-menu-item-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.rc-tabs-content {
  display: flex;
  width: 100%;
  margin-top: -1rem;
}

.rc-tabs-content-holder {
  flex: auto;
  margin-left: 1.5rem
}

.rc-tabs-content-animated {
  transition: margin 0.3s;
}

.rc-tabs-tabpane {
  width: 100%;
  flex: none;
}

.rc-tabs {
  display: flex;
}

.rc-tabs-top,
.rc-tabs-bottom {
  flex-direction: column;
}

.rc-tabs-top .rc-tabs-ink-bar,
.rc-tabs-bottom .rc-tabs-ink-bar {
  height: 3px;
}

.rc-tabs-top .rc-tabs-ink-bar {
  bottom: 0;
}

.rc-tabs-bottom .rc-tabs-nav {
  order: 1;
}

.rc-tabs-bottom .rc-tabs-content {
  order: 0;
}

.rc-tabs-bottom .rc-tabs-ink-bar {
  top: 0;
}

.rc-tabs-left.rc-tabs-editable .rc-tabs-tab,
.rc-tabs-right.rc-tabs-editable .rc-tabs-tab {
  padding-right: 32px;
}

.rc-tabs-left .rc-tabs-nav-wrap,
.rc-tabs-right .rc-tabs-nav-wrap {
  flex-direction: column;
}

.rc-tabs-left .rc-tabs-ink-bar,
.rc-tabs-right .rc-tabs-ink-bar {
  width: 3px;
}

.rc-tabs-left .rc-tabs-nav,
.rc-tabs-right .rc-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}

.rc-tabs-left .rc-tabs-nav-list,
.rc-tabs-right .rc-tabs-nav-list {
  flex-direction: column;
}

.rc-tabs-left .rc-tabs-nav-operations,
.rc-tabs-right .rc-tabs-nav-operations {
  flex-direction: column;
}

.rc-tabs-left .rc-tabs-ink-bar {
  right: 0;
}

.rc-tabs-right .rc-tabs-nav {
  order: 1;
}

.rc-tabs-right .rc-tabs-content {
  order: 0;
}

.rc-tabs-right .rc-tabs-ink-bar {
  left: 0;
}

.rc-tabs-rtl {
  direction: rtl;
}

.rc-tabs-dropdown-rtl {
  direction: rtl;
}

.rc-tabs {
  border: 0;
  font-size: 14px;
  overflow: hidden;
  height: 100vh;
  color: #013639
}

.rc-tabs-nav {
  display: flex;
  flex: none;
  position: relative;
  padding-bottom: 2rem;
  left: 1.5rem;
}

.rc-tabs-nav-measure,
.rc-tabs-nav-wrap {
  transform: translate(0);
  position: relative;
  display: inline-block;
  flex: auto;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
}

.rc-tabs-nav-measure-ping-left::before,
.rc-tabs-nav-wrap-ping-left::before,
.rc-tabs-nav-measure-ping-right::after,
.rc-tabs-nav-wrap-ping-right::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
}

.rc-tabs-nav-measure-ping-left::before,
.rc-tabs-nav-wrap-ping-left::before {
  left: 0;
  border-left: 1px solid #007F85;
}

.rc-tabs-nav-measure-ping-right::after,
.rc-tabs-nav-wrap-ping-right::after {
  right: 0;
  border-right: 1px solid #007F85;
}

.rc-tabs-nav-measure-ping-top::before,
.rc-tabs-nav-wrap-ping-top::before,
.rc-tabs-nav-measure-ping-bottom::after,
.rc-tabs-nav-wrap-ping-bottom::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
}

.rc-tabs-nav-measure-ping-top::before,
.rc-tabs-nav-wrap-ping-top::before {
  top: 0;
  border-top: 1px solid #007F85;
}

.rc-tabs-nav-measure-ping-bottom::after,
.rc-tabs-nav-wrap-ping-bottom::after {
  bottom: 0;
  border-top: 1px solid #007F85;
}

.rc-tabs-nav-list {
  display: flex;
  position: relative;
  transition: transform 0.3s;
}

.rc-tabs-nav-operations {
  display: flex;
}

.rc-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}

.rc-tabs-nav-more {
  border: 1px solid blue;
  background: rgba(255, 0, 0, 0.1);
}

.rc-tabs-nav-add {
  border: 1px solid green;
  background: rgba(0, 255, 0, 0.1);
}

.rc-tabs-tab {
  border: 0;
  background: rgba(255, 255, 255, 0.5);
  margin: 0;
  display: flex;
  outline: none;
  cursor: pointer;
  position: relative;
  font-weight: lighter;
  align-items: center;
  margin-left: 0.5rem;
  font-size: 16px;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.rc-tabs-tab-btn,
.rc-tabs-tab-remove {
  border: 0;
  background: transparent;
}

.rc-tabs-tab-btn {
  font-weight: inherit;
  line-height: 32px;
}

.rc-tabs-tab-remove:hover {
  color: #013639;
}

.rc-tabs-tab-active {
  font-weight: normal;
  color: #007F85;
}

.rc-tabs-ink-bar {
  position: absolute;
  background:#007F85;
  pointer-events: none;
}

.rc-tabs-ink-bar-animated {
  transition: all 0.3s;
}

.rc-tabs-extra-content {
  flex: none;
}
